@font-face {
    font-family: "icomoon";
    src: url("icomoon.ttf?prgmjp") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tensorflow:before {
    content: "\e908";
}
.icon-kotlin:before {
    content: "\e907";
}
.icon-c:before {
    content: "\e900";
}
.icon-cRelleno:before {
    content: "\e901";
}
.icon-cpp:before {
    content: "\e902";
}
.icon-cppRelleno:before {
    content: "\e903";
}
.icon-cs:before {
    content: "\e904";
}
.icon-csRelleno:before {
    content: "\e905";
}
.icon-tex:before {
    content: "\e906";
}
